.HeadingContainer{

    display:flex;
    justify-content:center;
    align-items:center;
    height: 100px;
    width: 100%;

}

.HeadingContainer__text{

    font-size: 30px;
    text-transform: uppercase;
    text-align: center

}